@import "./color-style.scss";

.light-theme {
    .ag-theme-alpine {
        .ag-side-bar {
            background-color: transparent;

            span.ag-side-button-label {
                color: $baseBlack;
            }
        }

        .ag-theme-alpine .ag-select-list-item {
            color: $baseBlack;
        }

        --ag-header-foreground-color: $baseRed;
        --ag-foreground-color: $baseBlack;

        --ag-row-hover-color: rgba(255, 255, 255, 0.1);

        --ag-selected-row-background-color: rgba(255, 165, 0, 0.2);

        /* Border styles */
        --ag-borders: solid 1px;
        --ag-border-radius: 0;

        .ag-header-cell {
            color: $baseRed;
        }

        /* Cell styling */
        .ag-cell {
            color: $baseBlack;
        }


        /* For alternating row colors */
        .ag-row-even {
            background-color: $baseWhite;
        }

        .ag-row-odd {
            background-color: transparent;
        }

        /* Pagination panel */
        .ag-paging-panel {
            color: $baseBlack;
            background-color: $baseWhite;
            border-top: 1px solid $Grey;
        }

        /* For filters and dropdowns */
        .ag-menu,
        .ag-popup {
            background-color: $baseWhite;
            color: $baseBlack;
            border: 1px solid #3a2000;
        }

        /* For column groups */
        .ag-header-group-cell {
            color: $baseRed;
        }

        .ag-icon {
            color: $baseBlack;
        }

        .ag-virtual-list-viewport.ag-column-select-virtual-list-viewport.ag-focus-managed {
            color: $baseBlack;
            height: 23pc;
        }

        .ag-filter-toolpanel {
            height: 200px;
        }

        .ag-group-title-bar.ag-filter-toolpanel-group-title-bar.ag-unselectable.ag-filter-toolpanel-group-level-0-header.ag-filter-toolpanel-header {
            color: $baseBlack;
        }

        .ag-filter-toolpanel-header.ag-filter-toolpanel-instance-header.ag-filter-toolpanel-group-level-1-header {
            color: $baseBlack;
        }

        .ag-set-filter-item-checkbox.ag-labeled.ag-label-align-right.ag-checkbox.ag-input-field {
            color: $baseBlack;
        }

        .ag-unselectable.ag-column-drop.ag-column-drop-vertical.ag-column-drop-rowgroup.ag-column-drop-vertical-rowgroup.ag-focus-managed.ag-column-drop-empty {
            color: $baseBlack;
        }

        span.ag-column-drop-empty-message.ag-column-drop-vertical-empty-message {
            color: $baseBlack;
        }

        .ag-unselectable.ag-column-drop.ag-column-drop-vertical.ag-column-drop-aggregation.ag-column-drop-vertical-aggregation.ag-focus-managed.ag-column-drop-empty.ag-last-column-drop {
            color: $baseBlack;
        }

        .ag-theme-alpine .ag-select-list {
            background-color: $baseWhite;
        }

        span.ag-column-drop-cell-text.ag-column-drop-vertical-cell-text {
            color: $baseBlack;
        }

        span.ag-column-drop-title.ag-column-drop-vertical-title {
            color: $baseBlack;
        }

        .ag-select-agg-func-item {
            background-color: $baseWhite;
            color: $baseBlack;
        }

        .ag-panel.ag-default-panel.ag-dialog.ag-ltr.ag-popup-child.ag-focus-managed {
            background: $baseWhite;
        }

        span.ag-panel-title-bar-title.ag-default-panel-title-bar-title {
            color: $baseBlack;
        }

        .ag-filter-no-matches {
            color: $baseBlack;
        }

        :where(.ag-theme-inputStyle-6) {
            & :where(.ag-ltr) :where(.ag-column-select-header-filter-wrapper, .ag-filter-toolpanel-search, .ag-mini-filter, .ag-filter-filter) {
                .ag-input-wrapper:before {
                    color: $baseBlack;
                }
            }
        }

        .ag-radio-button-input-wrapper.ag-checked::after {
            color: $baseBlack;
        }

        @media screen and (min-width: 1681px) and (max-width: 2560px) {
            .ag-virtual-list-viewport.ag-column-select-virtual-list-viewport.ag-focus-managed {
                height: 32pc;
            }
        }
        .ag-checkbox-input-wrapper.ag-checked::after {
            color: $baseBlack;
        }
    }

    .tlog-grid-container {
        @media screen and (min-width: 1681px) and (max-width: 2560px) {
            .ag-virtual-list-viewport.ag-column-select-virtual-list-viewport.ag-focus-managed {
                height: 28pc;
            }
        }
    }
}