@import "./color-style.scss";
@import "./support.scss";

//new styles for ag grid

/* AG Grid Dark Theme Customization */
.ag-theme-alpine {

    .ag-root-wrapper {
        background-color: transparent;
        border: 1px solid $Grey;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .ag-side-buttons {
        background-color: transparent;
    }

    .ag-side-bar {
        background-color: transparent;
        border-color: $Grey;

        span.ag-side-button-label {
            color: $baseWhite;
        }
    }

    .ag-wrapper.ag-picker-field-wrapper.ag-picker-collapsed {
        background-color: transparent;
        border: 1px solid $Grey;
    }

    ▼.ag-has-popup-positioned-under {
        background-color: transparent;
        border: 1px solid $Grey;
    }

    .ag-paging-panel>.ag-paging-page-size .ag-wrapper {
        background-color: transparent;
    }

    /* Make pagination selector dropdown background transparent */
    .ag-theme-alpine .ag-paging-panel .ag-select {
        background-color: transparent;
    }

    /* Ensure dropdown options also have a transparent background */
    .ag-theme-alpine .ag-select-list {
        background-color: #2a1500;
        border: 1px solid $Grey;
    }

    /* Optional: Change text color for visibility */
    .ag-theme-alpine .ag-select-list-item {
        color: $baseWhite;
    }

    --ag-header-foreground-color: $baseRed;
    --ag-foreground-color: $baseWhite;

    /* Row hover */
    --ag-row-hover-color: rgba(255, 255, 255, 0.1);

    /* Selection colors */
    --ag-selected-row-background-color: rgba(255, 165, 0, 0.2);

    /* Border styles */
    --ag-borders: solid 1px;
    --ag-border-radius: 0;

    /* Header styling */
    .ag-header {
        border-bottom: 1px solid $Grey;
    }

    .ag-header-cell-sortable .ag-header-cell-label {
        justify-content: center;
    }

    .ag-header-cell {
        color: $baseRed;
        font-weight: 400;
        border-right: 1px solid $Grey;
    }

    .ag-cell {
        color: $baseWhite;
        border-right: 1px solid $Grey;
        border-bottom: 1px solid $Grey;
        padding: 8px;
    }

    /* Row styling */
    .ag-row {
        border-bottom: 1px solid $Grey;
    }

    /* For alternating row colors */
    .ag-row-even {
        background-color: rgba(255, 255, 255, 0.1);
        cursor: pointer;
    }

    .ag-row-odd {
        background: transparent;
        cursor: pointer;
    }

    /* Pagination panel */
    .ag-paging-panel {
        color: $baseWhite;
        background: rgb(140 33 33 / 10%);
        border-top: 1px solid $Grey;
    }

    /* For filters and dropdowns */
    .ag-menu,
    .ag-popup {
        background-color: #1a0e00;
        color: $baseWhite;
        border: 1px solid #3a2000;
    }

    /* For column groups */
    .ag-header-group-cell {
        color: $baseRed;
        border-right: 1px solid $Grey;
        border-bottom: 1px solid $Grey;

        .ag-header-cell-comp-wrapper {
            justify-content: center;
        }
    }

    /* Remove default row hover effect */
    .ag-row-hover {
        background-color: rgb(140 33 33 / 10%);
    }

    .ag-icon {
        color: $baseWhite;
    }

    .ag-virtual-list-viewport.ag-column-select-virtual-list-viewport.ag-focus-managed {
        color: $baseWhite;
        height: 23pc;
    }

    .ag-filter-toolpanel {
        height: 200px;
    }

    .ag-group-title-bar.ag-filter-toolpanel-group-title-bar.ag-unselectable.ag-filter-toolpanel-group-level-0-header.ag-filter-toolpanel-header {
        color: $baseWhite;
        font-weight: 400;
        border: none;
    }

    span.ag-header-cell-text {
        font-weight: 400;
    }

    .ag-filter-toolpanel-header.ag-filter-toolpanel-instance-header.ag-filter-toolpanel-group-level-1-header {
        color: $baseWhite;
    }

    .ag-set-filter-item-checkbox.ag-labeled.ag-label-align-right.ag-checkbox.ag-input-field {
        color: $baseWhite;
    }

    .ag-unselectable.ag-column-drop.ag-column-drop-vertical.ag-column-drop-rowgroup.ag-column-drop-vertical-rowgroup.ag-focus-managed.ag-column-drop-empty {
        color: $baseWhite;
        border-color: $Grey;
    }

    span.ag-column-drop-empty-message.ag-column-drop-vertical-empty-message {
        color: $baseWhite;
    }

    .ag-unselectable.ag-column-drop.ag-column-drop-vertical.ag-column-drop-aggregation.ag-column-drop-vertical-aggregation.ag-focus-managed.ag-column-drop-empty.ag-last-column-drop {
        color: $baseWhite;
    }

    .ag-theme-alpine .ag-ltr input[class^=ag-][type=text] {
        padding-left: 22px;
    }

    .ag-side-button {
        border-color: $Grey;
    }

    // .ag-overlay-loading-center {
    //     background-color: none;
    //     color: #ffffff;
    //     font-size: 18px;
    //     font-weight: bold;
    //     padding: 20px;
    //     border-radius: 10px;
    //   }
    .ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-loading-wrapper {
        background: none;
    }

    .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value.ag-cell-focus {
        border-color: $Grey;
    }

    .ag-column-select.ag-column-panel-column-select {
        border-color: $Grey;
    }

    .ag-column-select-header {
        border: 1px solid $Grey;
    }

    span.ag-column-drop-empty-message.ag-column-drop-vertical-empty-message {
        border-color: $Grey;
    }

    .ag-root-wrapper.ag-layout-normal {
        height: 110%;
    }

    span.ag-column-drop-cell-text.ag-column-drop-vertical-cell-text {
        color: $baseWhite;
    }

    span.ag-column-drop-title.ag-column-drop-vertical-title {
        color: $baseWhite;
    }

    .ag-header-cell-moving {
        background-color: transparent;
    }

    .ag-select-agg-func-item {
        background-color: #1a0e00;
        color: $baseWhite;
    }

    .ag-panel.ag-default-panel.ag-dialog.ag-ltr.ag-popup-child.ag-focus-managed {
        background: #1a0e00;
    }

    span.ag-panel-title-bar-title.ag-default-panel-title-bar-title {
        color: $baseWhite;
    }

    .ag-filter-no-matches {
        color: $baseWhite;
    }

    .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-last-left-pinned.ag-cell-value {
        border-right-color: $Grey;
    }

    .ag-pinned-right-cols-container {
        border-left: 1px solid $Grey;
    }

    .ag-pinned-left-header {
        border-right: $Grey !important;
    }

    .ag-pinned-right-header {
        border-left: 1px solid $Grey !important;
    }

    input[class^=ag-]:not([type]),
    input[class^=ag-][type=text],
    input[class^=ag-][type=number],
    input[class^=ag-][type=tel],
    input[class^=ag-][type=date],
    input[class^=ag-][type=datetime-local],
    textarea[class^=ag-] {
        color: $baseBlack;
    }

    :where(.ag-theme-inputStyle-6) {
        & :where(.ag-ltr) :where(.ag-column-select-header-filter-wrapper, .ag-filter-toolpanel-search, .ag-mini-filter, .ag-filter-filter) {
            .ag-input-wrapper:before {
                margin-left: var(--ag-spacing);
                position: relative;
                color: $baseWhite;
                margin-right: 2px;
            }
        }
    }

    .ag-radio-button-input-wrapper.ag-checked::after {
        color: $baseWhite;
    }

    span.ag-column-select-column-label {
        display: flex;
    }

    @media screen and (min-width: 1280px) and (max-width: 1680px) {
        .ag-root-wrapper.ag-layout-normal {
            height: 110%;
        }
    }

    @media screen and (min-width: 1681px) and (max-width: 2560px) {
        .ag-root-wrapper.ag-layout-normal {
            height: 150%;
        }

        .ag-virtual-list-viewport.ag-column-select-virtual-list-viewport.ag-focus-managed {
            height: 32pc;
        }
    }

    .ag-side-button {
        &.ag-selected {
            background-color: none;
        }

        &:hover {
            background-color: none;
        }
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
        color: $baseWhite;
    }

    span.ag-header-group-text {
        font-weight: 400;
    }
}

.ag-header.ag-pivot-off.ag-header-allow-overflow {
    height: 80px !important;
}

.tlog-grid-container {
    .ag-header-cell {
        height: 80px !important;
        line-height: 1.3 !important;

        /* Improve text alignment and overflow handling */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5px;
        white-space: normal;
        overflow-wrap: break-word;
    }

    .ag-header.ag-pivot-off.ag-header-allow-overflow {
        min-height: 80px !important;
    }

    .ag-root-wrapper.ag-layout-normal {
        height: 90%;
    }

    .ag-virtual-list-viewport.ag-column-select-virtual-list-viewport.ag-focus-managed {
        height: 18pc;
    }

    @media screen and (min-width: 1280px) and (max-width: 1680px) {
        .ag-root-wrapper.ag-layout-normal {
            height: 90%;
        }
    }

    @media screen and (min-width: 1681px) and (max-width: 2560px) {
        .ag-root-wrapper.ag-layout-normal {
            height: 135%;
        }

        .ag-virtual-list-viewport.ag-column-select-virtual-list-viewport.ag-focus-managed {
            height: 28pc;
        }
    }
}