@import "./color-style.scss";

.light-theme {
  .equipmentsPage {
    .searchEquip {
      margin: 0 20px;

      .ant-input-affix-wrapper {
        background: $input-bg !important;
        border-radius: 20px;
        border: 1px solid rgba($light-theme-text-color, 0.2);

        .ant-input {
          background: transparent !important;
          border-radius: 0 !important;
          border: none !important;
        }

        .anticon {
          color: $baseRed;
        }
      }
    }
  }

  span.ant-tree-title {
    color: $baseBlack;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: $baseBlack;
  }

  .searchConsumabels {
    margin: 0 20px;

    .ant-input-affix-wrapper {
      background: $input-bg !important;
      border-radius: 20px;
      border: 1px solid rgba($light-theme-text-color, 0.2);

      .ant-input {
        background: transparent !important;
        border-radius: 0 !important;
        border: none !important;
      }

      .anticon {
        color: $baseRed;
      }
    }
  }

  .ant-layout-sider {
    background: $baseWhite;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .left-menu .ant-menu-item .ant-btn-link {
    color: $baseBlack;
  }

  .left-menu {
    .ant-menu-item-selected {
      a {
        color: $baseBlack;
      }

      .ant-btn-link {
        color: $baseRed;
      }
    }
  }

  .project-title {
    color: $light-theme-text-color;
  }

  //silder
  .flexslider,
  .fourflexslider {
    .rec-arrow {
      color: $light-theme-darkgray;
    }

    .rec-arrow-left {
      background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0%
        no-repeat padding-box;

      &.eWjCzc:hover:enabled,
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled,
      &.hJJzxj:focus:enabled {
        background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0%
          no-repeat padding-box;
        box-shadow: none;
        color: $light-theme-darkgray;
      }
    }

    .rec-arrow-right {
      background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0%
        no-repeat padding-box;

      &.eWjCzc:hover:enabled,
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled,
      &.hJJzxj:focus:enabled {
        background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0%
          no-repeat padding-box;
        box-shadow: none;
        color: $light-theme-darkgray;
      }
    }
  }

  .flexslider-small {
    .rec-arrow {
      color: $light-theme-darkgray;
    }

    .rec-arrow-left {
      background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0%
        no-repeat padding-box;

      &.eWjCzc:hover:enabled,
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled,
      &.hJJzxj:focus:enabled {
        background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0%
          no-repeat padding-box;
        box-shadow: none;
        color: $light-theme-darkgray;
      }
    }

    .rec-arrow-right {
      background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0%
        no-repeat padding-box;

      &.eWjCzc:hover:enabled,
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled,
      &.hJJzxj:focus:enabled {
        background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0%
          no-repeat padding-box;
        box-shadow: none;
        color: $light-theme-darkgray;
      }
    }
  }

  .slides {
    .slides-items {
      background: $baseWhite;
      box-shadow: none;
      color: $light-theme-text-color;

      &.active {
        background: $baseRed 0% 0% no-repeat padding-box;
        color: $baseWhite;
      }
    }
  }

  //project details
  .project-details-wrapper {
    color: $light-theme-text-color;

    // color:  $baseWhite;
    .title {
      color: $baseRed;

      .anticon-close {
        color: $light-theme-text-color;
        cursor: pointer;
      }
    }
  }

  // tab
  .custom-tab {
    .ant-tabs-nav {
      background: $baseWhite;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }

    .ant-tabs-tab {
      color: $light-theme-text-color;
    }

    .ant-tabs-tab-btn:active {
      color: $baseRed;
    }
  }

  .custom-line {
    background: $light-theme-border-color;
  }

  .border-right {
    border-color: $light-theme-border-color;
  }

  hr {
    color: $light-theme-text-color;
  }

  h4.ant-typography,
  .ant-typography h4 {
    color: $light-theme-text-color !important;
  }

  .ant-typography {
    color: $light-theme-text-color !important; // !important;
  }

  //card
  .custom-card {
    background: $baseWhite 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .ant-table-cell,
  .export-link {
    color: $light-theme-text-color;
  }

  .ant-modal-content {
    backdrop-filter: none;
    background: #f4f5fe;
  }

  .report-table {
    .ant-table-thead {
      background: #f4f5fe;
    }
  }

  .user-name {
    p {
      color: $baseBlack;
    }

    .ant-avatar {
      background-color: $light-theme-border-color;
    }
  }

  //modal

  .ant-modal {
    .ant-modal-footer {
      .ant-btn {
        color: $light-theme-text-color;
        border-color: $light-theme-text-color;
      }

      .ant-btn-primary {
        background: $baseRed;
        color: $baseWhite;
        border-color: $baseWhite;
      }
    }

    .ant-table-thead {
      font-size: $font-size-base;

      tr {
        th {
          background: transparent !important;
          border-bottom: none !important;

          &.ant-table-cell-scrollbar {
            box-shadow: none;
          }
        }

        .main-title {
          background: rgba($light-theme-text-color, 0.8) 0% 0% no-repeat
            padding-box !important;
        }
      }
    }
  }

  .ant-modal-header {
    border-color: $light-theme-border-color;

    .ant-modal-title {
      color: $light-theme-text-color;
    }
  }

  //table
  .ant-table {
    color: $light-theme-text-color;
  }

  .ant-table-tbody {
    tr.ant-table-row {
      &:nth-child(even) {
        background: rgba($baseWhite, 0.6) !important;
      }
    }
  }

  //scroll bar

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(197, 197, 197, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(209, 209, 209);
    outline: 1px solid rgb(223, 222, 222);
  }

  //button
  .refresh {
    background: transparent;
    color: $light-theme-text-color;
    border-color: $light-theme-text-color;

    &:hover,
    &:focus,
    &:active {
      color: $light-theme-text-color;
      border-color: $light-theme-text-color;
    }
  }

  //datepicker
  .ant-picker-panel-container {
    background: $baseWhite;
  }

  .ant-picker-header {
    color: $light-theme-text-color;
    border-color: $light-theme-border-color;

    button {
      color: rgba($light-theme-text-color, 0.6);

      &:hover {
        color: rgba($light-theme-text-color, 0.9);
      }
    }

    .ant-picker-header-view {
      button {
        color: $light-theme-text-color;
      }
    }
  }

  .ant-picker-content {
    th {
      color: rgba($light-theme-text-color, 0.8);
    }
  }

  .ant-picker-cell {
    color: rgba($light-theme-text-color, 0.6);
  }

  .ant-picker-cell-in-view {
    color: $light-theme-text-color;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: $light-theme-text-color;
    background: $baseRed;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: $baseRed;
  }

  .ant-picker-today-btn {
    color: $light-theme-text-color;
  }

  .ant-picker-panel {
    border: none;

    .ant-picker-footer {
      border-color: $light-theme-border-color;
    }
  }

  .ant-form-item-label > label {
    color: $light-theme-text-color;
  }

  //input
  .ant-select-selection-item {
    color: $light-theme-text-color;
  }

  .ant-select-item-option-content {
    color: $light-theme-text-color;
  }

  .ant-input {
    color: $light-theme-text-color;
    border: 1px solid $light-theme-border-color !important;
  }

  .ant-input:focus,
  .ant-input:hover,
  .ant-input-focused {
    border: 1px solid $light-theme-border-color !important;
  }

  .ant-select-dropdown {
    background: $baseWhite;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: $baseRed;
    color: $light-theme-text-color;
  }

  ::placeholder {
    color: rgba($light-theme-text-color, 0.6);
  }

  .edit-session {
    .ant-picker {
      border: 1px solid $light-theme-border-color !important;
      border-right: none !important;
    }

    .ant-picker-suffix {
      color: grey;
    }

    ::placeholder {
      color: grey;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: grey;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: grey;
    }
  }

  .ant-picker-input > input {
    color: $light-theme-text-color;
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none !important;
  }

  .top-bar-input {
    color: $light-theme-text-color;
    background: rgba($light-theme-text-color, 0.2);
  }

  .filter-none {
    color: $light-theme-text-color;
    background: rgba($light-theme-text-color, 0.2);
  }

  .ant-radio-wrapper {
    color: $light-theme-text-color;
  }

  .ant-radio-disabled + span {
    color: $light-theme-text-color;
  }

  .ant-avatar {
    background: rgba($light-theme-text-color, 0.2) !important;
  }

  .ant-layout-sider-trigger {
    color: $light-theme-text-color;
    background: transparent
      linear-gradient(180deg, #00000000 0%, #00000036 100%) 0% 0% no-repeat
      padding-box;
  }

  td .theme-color {
    color: $light-theme-text-color !important;
  }

  .poweredby {
    p {
      color: $light-theme-text-color;
    }
  }

  //coming soon
  .coming-soon {
    background: rgba($light-theme-text-color, 0.3);

    h1 {
      color: $light-theme-text-color;
    }

    p {
      color: $light-theme-text-color;
    }
  }

  .ant-empty-normal .ant-empty-image {
    opacity: 0.3;
  }

  .ant-empty-description {
    color: $light-theme-text-color;
  }

  .login_copy {
    position: absolute;
    color: #111b26;
    font-size: 14px;
    font-weight: 500;
    bottom: 10px;
    text-align: center;

    a {
      color: #111b26;
    }
  }

  // login
  .login-form {
    h1 {
      color: $baseBlack;
    }

    .login-form-forgot {
      color: $baseBlack;
    }

    .ant-input {
      height: 50px;
      padding: 6.5px 18px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.2);
    }

    .login-form-button {
      height: 50px;
      color: $baseWhite;
      background: $baseRed;
      border-color: $baseRed;
      font-weight: 700;
      font-size: $font-size-xxl;

      &.ant-btn:hover,
      &.ant-btn:focus,
      &.ant-btn:active {
        color: $baseBlack;
        border-color: $baseRed;
        background: transparent;
      }
    }

    .resendBtn {
      width: 37% !important;
      font-size: 14px !important;
      height: 20px !important;
      border: 0 !important;
      margin: 0 !important;
      color: $baseRed;
      padding-top: 0 !important;
      padding-left: 5px !important;
      margin-left: 10px !important;
      font-weight: 500 !important;
      background: none !important;

      &:hover {
        background: none !important;
        border: 0 !important;
        color: $light-theme-text-color !important;
      }
    }

    .anticon {
      color: $light-theme-text-color;
    }

    .forgotPswdForm {
      .ant-form-item-feedback-icon-success {
        color: #49aa19;

        .anticon {
          color: #49aa19;
        }
      }

      .ant-form-item-feedback-icon-warning {
        color: #d89614;

        .anticon {
          color: #d89614;
        }
      }

      .verificationCode {
        input {
          border: 1px solid $light-theme-text-color;
          color: $light-theme-text-color;
        }
      }

      .sperator {
        color: $light-theme-text-color;
      }
    }
  }

  .login-form-signup_text {
    color: $light-theme-text-color !important;
  }

  .client-select {
    .ant-select-selector {
      border: 1px solid $light-theme-border-color !important;
    }
  }

  .date-picker {
    .ant-picker {
      border: 1px solid $light-theme-border-color !important;
    }

    .ant-picker-suffix {
      color: grey;
    }

    ::placeholder {
      color: grey;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: grey;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: grey;
    }
  }

  .statement_work,
  .logDetails_table {
    .ant-table-placeholder {
      background: transparent !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background: transparent !important;
      }

      .ant-table-cell {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: transparent !important;

        // z-index: 1000;
        &:hover {
          background: transparent !important;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    tr:nth-child(even) {
      td:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fbfbff !important;
        // z-index: 1000;
      }

      &:hover>td:last-child // th:last-child
      {
        background: #fbfbff !important;
      }
    }

    tr:nth-child(odd) {
      td:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #f4d7e3 !important;
        // z-index: 1000;
      }

      &:hover>td:last-child // th:last-child
      {
        background: #f4d7e3 !important;
      }
    }

    .ant-table-tbody tr.ant-table-row:nth-child(odd) {
      background: #f4d7e3 !important;
    }

    tr:first-child {
      td:last-child {
        background-color: transparent !important;
      }
    }
  }

  .logDetails_table {
    tr:nth-child(even) {
      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #fbfbff !important;

        &:hover {
          background: #fbfbff !important;
        }
      }
    }

    tr:nth-child(odd) {
      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #f4d7e3 !important;

        &:hover {
          background: #f4d7e3 !important;
        }
      }
    }

    tr.ant-table-row:hover:nth-child(odd) {
      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #f4d7e3 !important;
      }
    }

    tr.ant-table-row:hover:nth-child(even) {
      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #fbfbff !important;
      }
    }
  }

  .logTabel-modal {
    .ant-modal-body {
      padding-bottom: 0 !important;
    }

    .ant-table-thead {
      font-size: $font-size-base;

      tr {
        th {
          background: #f5a1ad !important;
          border-bottom: none !important;
          vertical-align: top;
          white-space: nowrap;
          font-weight: 500 !important;
          color: $baseRed;
          padding: 10px;

          &.ant-table-cell-scrollbar {
            box-shadow: none;
          }
        }
      }
    }

    .ant-table-tbody tr.ant-table-row:nth-child(even) {
      background: #fbfbff !important;
    }

    .ant-table-tbody tr.ant-table-row td {
      padding: 10px;
    }

    .like,
    .unlike {
      border-radius: 25px;
      border: 1px solid;
      padding: 5px;
    }

    .like {
      color: #7eff12;
      background: rgba($baseWhite, 0.6);
    }

    .unlike {
      color: #ff0000;
      background: rgba($baseWhite, 0.6);
    }

    .pending {
      background: transparent;
      border: 1px solid;
    }
  }

  .inspection-logs {
    .log-filter {
      font-size: 20px;
      margin: 0 18px;
      cursor: pointer;
      color: $darkGrey;
    }

    .ant-btn {
      &:hover {
        color: $baseRed !important;
      }

      &:focus,
      &:active {
        color: $baseWhite;
      }
    }
  }

  .ant-input-number {
    background: #f6f7fe;
    border-color: #d9dadf;
    color: $light-theme-text-color;
  }

  .log-calender {
    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date,
    .log-calender
      .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date,
    .log-calender
      .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date-today,
    .log-calender
      .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date-today {
      background: #6c757d;
    }

    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-calendar-date-today {
      border-color: #111b26;
      border-top: 2px solid #111b26 !important;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
      border-top: 2px solid $baseRed;
    }

    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date
      .ant-picker-calendar-date-value,
    .log-calender
      .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date
      .ant-picker-calendar-date-value,
    .log-calender
      .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value,
    .log-calender
      .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-cell-selected:hover
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value {
      color: #ffffffd9;
    }

    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-calendar-date-today
      .ant-picker-calendar-date-value {
      color: $light-theme-text-color;
    }

    .ant-picker-calendar-header {
      background: #ffffff;
    }

    .ant-picker-calendar-full .ant-picker-panel {
      background: #ffffff;
    }

    .ant-radio-button-wrapper {
      color: $light-theme-text-color;
    }
  }

  .log-calender {
    .events {
      .ant-typography {
        color: white !important;
      }
    }
  }

  .log-select-pipeline {
    .ant-select-arrow {
      color: $light-theme-text-color;
    }

    .ant-select-selection-placeholder {
      color: $light-theme-text-color;
    }
  }

  .itp-select {
    .ant-select-arrow {
      color: $baseRed;
    }
  }

  .ant-select-selection-placeholder {
    color: $light-theme-text-color;
  }

  .no_image {
    color: #d9dadf;
  }

  .delete_station_list_icon {
    background: #dee2e6;
    color: $baseRed;

    &:hover {
      background: #2b2021;
    }
  }

  .calender_header {
    background: #dee2e6;
  }

  .log-table {
    .ant-table-pagination.ant-pagination {
      color: $light-theme-text-color;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      .anticon-left,
      .anticon-right {
        color: $light-theme-text-color;
      }
    }
  }

  .dated {
    background-color: #dee2e6;
  }

  .ant-picker-cell-disabled::before {
    background: #3030303b;
  }

  .ant-spin-text {
    color: $light-theme-text-color;
  }

  .pipeline-modal {
    .ant-input[disabled] {
      color: $light-theme-text-color;
    }

    .ant-btn-default {
      color: $light-theme-text-color;
    }

    .ant-upload-list {
      color: $light-theme-text-color;
    }

    .certificate-list {
      color: $light-theme-text-color;
    }

    .anticon-paper-clip {
      color: $light-theme-text-color;
    }

    .anticon-delete {
      color: $light-theme-text-color;
    }
  }

  .user_photo {
    background: $baseRed;
    color: white;
  }

  .change_profile_password {
    color: $baseRed !important;
  }

  .ant-input-password-icon.anticon {
    color: $light-theme-text-color;

    &:hover {
      color: $light-theme-text-color !important;
    }
  }

  .team_tabs {
    .btn_left,
    .btn_right {
      color: $light-theme-text-color;
      background: white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000001a;
      height: 40px;

      &:active,
      &:hover,
      &:focus {
        color: $light-theme-text-color;
      }
    }

    .btnActive {
      color: $baseWhite !important;
      background: $baseRed;
      border: 1px solid $baseRed;
    }
  }

  .equip_table {
    .ant-table-tbody tr:hover {
      background: #dee2e6 !important;

      .edit-hover {
        color: $baseRed !important;
      }
    }
  }

  .equipment_logs {
    .equip_tabs {
      text-align: center;
      margin-bottom: 20px;
      background: #ffffff !important;
      border-radius: 25px;
      padding: 8px;
      box-shadow: 1px 1px 4px 0px #808080b8 !important;
    }

    .ant-btn {
      color: $baseRed;
    }

    .active {
      color: $baseWhite;
      border: 1px solid $baseRed;
      background: $baseRed !important;
    }
  }

  .calenderView {
    .ant-picker {
      border: 1px solid $light-theme-text-color;
    }
  }

  .calenderRangeView {
    .ant-picker {
      border: 1px solid $light-theme-text-color;
    }
  }

  .maintenance-select {
    .ant-select-selector {
      border: 1px solid $light-theme-border-color !important;
    }

    .ant-select-selection-overflow {
      padding: 6px 12px;

      .ant-select-selection-item {
        border-radius: 25px;
        color: $light-theme-text-color;
        width: 96%;
        padding: 0px 10px;
        background: $baseWhite;
        border: 1px solid #dee2e6;

        .anticon-close {
          color: $baseRed;
        }
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #dee2e6 !important;
  }

  .ant-select-item-option-state {
    color: $baseBlack;
  }

  .admin {
    .admin_card {
      background: white;
      color: $light-theme-text-color;

      .anticon {
        color: $baseRed;

        &:hover {
          color: $baseWhite;
        }
      }
    }
  }

  .switch-container {
    color: $baseBlack;
  }

  .ant-table-container table > thead > tr th {
    color: red;
  }

  .ant-table-pagination.ant-pagination {
    color: $baseBlack;
  }

  // SideBar username style

  .left-menu .ant-menu-item {
    color: $baseBlack;
  }

  .sidebar-nav {
    .switchTheme span.menu-content {
      color: $baseBlack;
    }
    .switchTheme span.menu-content-ver {
      color: $baseBlack !important;
    }

    .username {
      color: $baseBlack;
    }
  }

  //slogData weld number filter

  span.anticon.anticon-close-circle {
    background: $baseBlack;
    border-radius: 6px;
    color: $baseWhite;
    border: 1px solid $baseBlack;
  }

  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: middle;
    color: $baseBlack;
  }

  span.anticon.anticon-left {
    color: $baseBlack;
    vertical-align: middle;
  }

  span.anticon.anticon-right {
    color: $baseBlack;
    vertical-align: middle;
  }

  .ant-select-arrow .anticon {
    color: $baseBlack;
    position: relative;
    bottom: 8px;
  }

  h6.filter_by {
    color: $baseBlack;
  }

  .ant-picker {
    border-color: $baseBlack;
  }

  .no-map-data-available {
    color: $baseBlack;
  }

  // .map-parent {
  //   *.ant-switch-handle::before {
  //     top: -1px;
  //     bottom: 2px;
  //   }
  // }
  .custom-radio-group {
    background-color: rgb(244, 245, 254);
  }

  .custom-radio-group .ant-radio-button-wrapper {
    background: rgb(244, 245, 254);
    color: $baseBlack;
  }

  .custom-radio-group .ant-radio-button-wrapper-checked {
    background: $baseRed;
    color: white;
    border-color: $baseRed;
  }

  //tlogdata style

  .slogweld-title {
    span.anticon.anticon-left.leftoutlined {
      color: $baseBlack;
    }

    .slogweld-header {
      color: $baseBlack;
    }

    h2.slogweld-header-weldnuber {
      color: $baseBlack;
    }

    .tolerance-lable {
      color: $baseBlack;
    }

    .boundary-input {
      button.ant-btn.ant-btn-default.refresh-download {
        color: $baseBlack;
      }

      .re-generate-btn:disabled {
        background: $Grey;
      }
    }
  }

  .slog-NoData {
    color: $baseBlack;
  }

  .status-cofig-table {
    .slop-time span.slop-span {
      color: $baseBlack;
    }

    .status-cancel-btn {
      border: 1px solid $baseBlack;
      color: $baseBlack;
    }

    td.ant-table-cell.rowtitle.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      background: $baseWhite;
    }

    th.ant-table-cell.rowtitle.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      background: $baseWhite;
    }

    td.ant-table-cell.rowtitle.ant-table-cell-fix-left.ant-table-cell-fix-left-last.ant-table-cell-row-hover {
      background: $baseWhite !important;
    }

    .slop-time {
      .ant-input-number.slop-input {
        background: $baseWhite;
      }
    }

    .slop-time .status-selector .ant-select-selector {
      background: $baseWhite;
    }
  }

  //drawer style
  .drawer {
    .ant-drawer-content {
      background: #ffffff;
      background-size: cover;
    }

    button.ant-drawer-close {
      color: $baseBlack;
    }

    .ant-drawer-title {
      color: $baseBlack;
      margin-top: 4px;
    }

    li.ant-list-item {
      color: $baseBlack;
    }

    button.ant-btn.ant-btn-default {
      color: $baseBlack;
    }
  }

  h6.mx-3.mt-1 {
    color: $baseBlack;
  }

  .iwm-header {
    .iwm-checkbox {
      background: $baseWhite;

      .ant-checkbox + span {
        color: $baseBlack;
      }
    }
  }

  .Graph-background {
    background: $baseWhite;
  }

  .email-popup-text {
    color: $baseBlack;

    .modal-email-button {
      color: $baseBlack;
    }

    .modal-email-button:hover {
      color: $baseBlack;
      border-color: $baseBlack;
    }
  }

  .ant-notification {
    span.anticon.anticon-close-circle {
      background: transparent;
      border-radius: 6px;
      color: $baseRed;
      border: 1px solid $baseBlack;
    }
  }

  .weld-div {
    background: $baseWhite;

    .weld-flex {
      .selectweld {
        button.ant-btn.ant-btn-default.selectweldbtns {
          color: $baseBlack;
          border-color: $Grey;
        }

        button.ant-btn.ant-btn-default.selectweldbtns:hover {
          color: $baseBlack;
          border-color: $Grey;
        }

        button.ant-btn.ant-btn-primary:hover {
          color: $baseWhite;
        }

        button:focus:not(:focus-visible) {
          color: $baseWhite;
        }
      }

      .pqrButtondiv {
        button.ant-btn.ant-btn-primary.pqrButton:hover {
          color: $baseWhite;
        }

        button:focus:not(:focus-visible) {
          color: $baseWhite;
        }
      }
    }
  }

  .header {
    background-color: $baseWhite;
  }

  .pqr-gen-div {
    .sr_no {
      color: $baseBlack;
    }

    .row {
      background-color: $baseWhite;
    }

    .weld-number {
      background: #f0eeee;
      color: $baseBlack;
    }

    .percentage {
      span.boundary-percetage {
        background-color: #f0eeee;
        color: $baseBlack;
      }
    }
  }

  .status-table-limit {
    .select-job-no {
      color: $baseBlack;
    }
  }

  .pqr-report-div {
    .selected-weld-numbers {
      span.ant-tag.options-show {
        color: $baseBlack;
        background: $baseWhite;

        span.anticon.anticon-close.ant-tag-close-icon {
          color: $baseBlack;
        }
      }
    }
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: rgba($light-theme-text-color, 0.5) ;
  }

  span.ant-pagination-item-ellipsis {
    color: rgba($light-theme-text-color, 0.5) !important;
  }
  .selected-options-parent {
    .custom-ant-button-secondary {
      color: $baseBlack;

      &:hover,
      &:active,
      &:focus {
        color: $baseBlack;
        border: 1px solid $baseBlack;
      }
    }
    .heading-check {
      color: $baseBlack;
    }
  }

  .apexcharts-menu-icon svg {
    fill: $baseBlack !important;
  }
  .single-line-graph,
  .line-graph{
    span.anticon.anticon-menu.ant-dropdown-trigger {
      color: $baseBlack;
    }
  }
}

.light-theme .ant-modal-header .ant-modal-title {
  color: $baseRed;
}

input#basic_endDate {
  opacity: 0.9;
}
